import type { ReviewProps } from './review';
import React from 'react';
import { Carousel, Wrap } from '@leafwell/components';
import Image from 'next/image';
import Review from './review';
import HeadingBody from './heading-body';

export type ReviewsCarouselProps = {
  center?: boolean;
  items: ReviewProps[];
  title?: string;
  intro?: string;
  hasTrustpilot?: boolean;
  quoted?: boolean;
  variant?: 'minimal' | 'full';
  sectionId?: string;
};

const Reviews: React.FC<ReviewsCarouselProps> = ({
  center = true,
  title,
  intro,
  items,
  hasTrustpilot = false,
  quoted = false,
  sectionId,
  variant,
}) =>
  items ? (
    <div
      id={sectionId}
      className="md:w-full overflow-hidden mx-auto pt-10 pb-10 md:pt-12 md:pb-24 "
    >
      <Wrap size="large">
        <div className={`${center ? 'text-center' : ''}`}>
          {title?.length > 0 && (
            <HeadingBody className="!mb-2" level="2" title={title} />
          )}
          {intro?.length > 0 ? <p>{intro}</p> : null}
        </div>
      </Wrap>
      <Wrap className="pt-10 px-5">
        <Carousel slideClassName="max-w-full md:max-w-xl mr-8" autoPlay={true}>
          {items.map((item, index) => (
            <Review
              {...item}
              key={index}
              className="lg:text-left"
              quoted={quoted}
              variant={variant}
            />
          ))}
        </Carousel>
      </Wrap>

      {hasTrustpilot && (
        <div
          className="trustpilot-widget mt-8"
          data-locale="en-US"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="63caa6848fcd1c82bbeac42d"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
          data-min-review-count="50"
          data-style-alignment="center"
        >
          <a
            id="profile-link"
            style={{ textAlign: 'center' }}
            target="_blank"
            href="https://www.trustpilot.com/review/leafwell.com?utm_medium=trustbox&amp;utm_source=MicroReviewCount"
          >
            <div id="tp-widget-rating" className="tp-widget-rating">
              <span id="translations-main">See our reviews on</span>
              <Image
                style={{
                  display: 'inline-block',
                  width: '94px',
                  marginLeft: '0.25rem',
                  verticalAlign: 'sub',
                }}
                src="/trustpilot.svg"
                alt="trustpilot"
                width={94}
                height={23}
              />
            </div>
          </a>
        </div>
      )}
    </div>
  ) : null;

export default Reviews;
